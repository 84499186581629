import React from 'react';
import { graphql } from 'gatsby';

import SectionSocials from 'components/common/SectionSocials';

import { PageBuilderSocialsStyled } from './PageBuilderSocialsStyled';

export default function PageBuilderSocials({ data, backgroundImages }) {
  const {
    socialsHeadline,
    socialsSubline,
    socialsInstagramText,
    socialsInstagramLink,
    socialsInstagramTeaserBackground,
    socialsInstagramTeaserText,
    socialsInstagramTeaserLink,
    socialsFacebookText,
    socialsFacebookLink,
    socialsFacebookTeaserBackground,
    socialsFacebookTeaserLink,
    socialsYoutubeText,
    socialsYoutubeLink,
    socialsYoutubeTeaserBackground,
    socialsYoutubeTeaserLink,
    socialsTwitterText,
    socialsTwitterLink,
  } = data;
  return (
    <PageBuilderSocialsStyled>
      <SectionSocials
        headline={socialsHeadline?.content}
        subline={socialsSubline?.content}
        backgroundImage={backgroundImages?.[1]}
        instagramText={socialsInstagramText}
        instagramLink={socialsInstagramLink?.url}
        instagramTeaserBackground={socialsInstagramTeaserBackground?.[0]}
        instagramTeaserBackgroundAlt='Barista che spilla birra tedesca Warsteiner in un bicchiere tulpe'
        instagramTeaserText={socialsInstagramTeaserText}
        instagramTeaserLink={socialsInstagramTeaserLink?.url}
        facebookText={socialsFacebookText}
        facebookLink={socialsFacebookLink?.url}
        facebookTeaserBackground={socialsFacebookTeaserBackground?.[0]}
        facebookTeaserBackgroundAlt=''
        facebookTeaserLink={socialsFacebookTeaserLink?.url}
        youtubeText={socialsYoutubeText}
        youtubeLink={socialsYoutubeLink?.url}
        youtubeTeaserBackground={socialsYoutubeTeaserBackground?.[0]}
        youtubeTeaserBackgroundAlt='Pollo barbecue alla birra tedesca Warsteiner'
        youtubeTeaserLink={socialsYoutubeTeaserLink?.url}
        twitterText={socialsTwitterText}
        twitterLink={socialsTwitterLink?.url}
      />
    </PageBuilderSocialsStyled>
  );
}

export const query = graphql`
  fragment PageBuilderSocialsQuery on Craft_PageBuilderNeoSocials {
    id
    socialsHeadline {
      content
    }
    socialsSubline {
      content
    }
    socialsTwitterText
    socialsTwitterLink {
      url
    }
    socialsFacebookText
    socialsFacebookLink {
      url
    }
    socialsFacebookTeaserBackground {
      ...CraftImageFluid
    }
    socialsFacebookTeaserLink {
      url
    }
    socialsInstagramText
    socialsInstagramLink {
      url
    }
    socialsInstagramTeaserBackground {
      ...CraftImageFluid
    }
    socialsInstagramTeaserText
    socialsInstagramTeaserLink {
      url
    }
    socialsYoutubeLink {
      url
    }
    socialsYoutubeText
    socialsYoutubeTeaserBackground {
      ...CraftImageFluid
    }
    socialsYoutubeTeaserLink {
      url
    }
  }
`;
