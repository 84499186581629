import React from 'react';
import PropTypes from 'prop-types';

import { parseAsContent } from 'utils/parser';

import Grid from 'components/common/Grid';
import GridItem from 'components/common/GridItem';
import HeadlineGold from 'components/common/HeadlineGold';
import Text from 'components/common/Text';
import Link from 'components/common/Link';
import Image from 'components/common/ImageGatsbyCraft';
import WrapSection from 'components/common/WrapSection';

import {
  SectionSocialsHeadStyled,
  SectionSocialsColumnStyled,
  SectionSocialsLinkStyled,
  SectionSocialsFacebookStyled,
  SectionSocialsFacebookIconStyled,
  SectionSocialsFacebookTeaserIconStyled,
  SectionSocialsFacebookContentStyled,
  SectionSocialsFacebookSublineStyled,
  // SectionSocialsTwitterStyled,
  // SectionSocialsTwitterIconStyled,
  // SectionSocialsTwitterContentStyled,
  // SectionSocialsTwitterSublineStyled,
  SectionSocialsInstagramStyled,
  SectionSocialsInstagramIconStyled,
  SectionSocialsInstagramContentStyled,
  SectionSocialsInstagramSublineStyled,
  SectionSocialsInstagramTeaserTextStyled,
  SectionSocialsInstagramTeaserIconStyled,
  SectionSocialsYoutubeStyled,
  SectionSocialsYoutubeIconStyled,
  SectionSocialsYoutubeContentStyled,
  SectionSocialsYoutubeSublineStyled,
  SectionSocialsYoutubeTeaserStyled,
  SectionSocialsYoutubeTeaserIconStyled,
} from './SectionSocialsStyled';

export default function SectionSocials({
  headline,
  subline,
  backgroundImage,
  instagramTeaserBackground,
  instagramTeaserBackgroundAlt,
  instagramTeaserText,
  instagramTeaserLink,
  instagramText,
  instagramLink,
  facebookText,
  facebookLink,
  facebookTeaserBackground,
  facebookTeaserBackgroundAlt,
  facebookTeaserLink,
  youtubeText,
  youtubeLink,
  youtubeTeaserBackground,
  youtubeTeaserBackgroundAlt,
  youtubeTeaserLink,
  // twitterText,
  // twitterLink,
}) {
  return (
    <WrapSection backgroundImage={backgroundImage}>
      <Grid>
        <GridItem>
          <SectionSocialsHeadStyled>
            <HeadlineGold element="h3">
              {parseAsContent(headline?.content || headline, {
                onlyBasicTags: true,
              })}
            </HeadlineGold>
            <Text>
              {parseAsContent(subline?.content || subline, {
                onlyBasicTags: true,
              })}
            </Text>
          </SectionSocialsHeadStyled>
        </GridItem>
        <GridItem laptop={1 / 3}>
          <Link to={instagramTeaserLink} target="_blank">
            <SectionSocialsColumnStyled aspectRatio={1}>
              <Image
                args={{ maxWidth: 1920 }}
                fluid={instagramTeaserBackground}
                altText={instagramTeaserBackground.altText}
              />
              <SectionSocialsInstagramTeaserIconStyled type="socialInstagram" />
              <SectionSocialsInstagramTeaserTextStyled>
                {instagramTeaserText}
              </SectionSocialsInstagramTeaserTextStyled>
            </SectionSocialsColumnStyled>
          </Link>
        </GridItem>
        <GridItem laptop={1 / 3}>
          <Link to={facebookTeaserLink} target="_blank">
            <SectionSocialsColumnStyled aspectRatio={1}>
              <Image
                args={{ maxWidth: 1920 }}
                fluid={facebookTeaserBackground}
                altText={facebookTeaserBackground.altText}
              />
              <SectionSocialsFacebookTeaserIconStyled type="socialFacebook" />
            </SectionSocialsColumnStyled>
          </Link>
        </GridItem>
        <GridItem laptop={1 / 3}>
          <Link to={youtubeTeaserLink} target="_blank">
            <SectionSocialsColumnStyled aspectRatio={1}>
              <Image
                args={{ maxWidth: 1920 }}
                fluid={youtubeTeaserBackground}
                altText={youtubeTeaserBackground.altText}
              />
               <SectionSocialsYoutubeTeaserIconStyled
                type="socialYoutube"
                width={56}
                height={56}
              />
            </SectionSocialsColumnStyled>
          </Link>
        </GridItem>
      </Grid>
    </WrapSection>
  );
}

SectionSocials.propTypes = {
  instagramTeaserLink: PropTypes.string,
  instagramLink: PropTypes.string,
  facebookLink: PropTypes.string,
  facebookText: PropTypes.string,
  // twitterLink: PropTypes.string,
  // twitterText: PropTypes.string,
  youtubeLink: PropTypes.string,
  youtubeText: PropTypes.string,
  youtubeTeaserLink: PropTypes.string,
};

SectionSocials.defaultProps = {
  instagramTeaserLink: 'https://www.instagram.com/p/Bsdfr16FrLX/',
  instagramLink: 'https://www.instagram.com/warsteiner/',
  facebookLink: 'https://www.facebook.com/Warsteiner/',
  facebookText: 'Facebook',
  // twitterLink: 'https://twitter.com/warsteiner?lang=de',
  // twitterText: 'Twitter',
  youtubeLink: 'https://www.youtube.com/user/WarsteinerVideos',
  youtubeText: 'YouTube',
  youtubeTeaserLink: 'https://www.youtube.com/watch?v=cUaK6yGOxSA',
};
