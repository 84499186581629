import styled from 'utils/emotion';
import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';

export const WrapSectionStyled = styled('section')(
  {
    position: 'relative',
    overflow: 'hidden',
  },
  (props) =>
    props.spaceBelow == true && {
      padding: `${toPx(props.theme.spaceDouble)} 0`,
      [media('tabletFluid')]: {
        padding: `${toPx(props.theme.spaceFive)} 0`,
      },
    },
  (props) =>
    props.spaceBelow == false && {
      padding: `${toPx(props.theme.spaceDouble)} 0 0 0`,
      [media('tabletFluid')]: {
        padding: `${toPx(props.theme.spaceFive)} 0 0 0`,
      },
    },
  (props) =>
    props.flex && {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
);

export const BackgroundStyled = styled('div')(
  {
    position: 'absolute',
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
    top: 0,
    left: 0,
  },
  (props) =>
    props.backgroundImageProducts && {
      top: '50%',
      transform: 'translate(0,-50%)',
      [media('mobile', 'tablet')]: {
        top: '10%',
        transform: 'translate(-20%,0)',
      },
    },
  (props) =>
    props.flip && {
      transform: 'scaleX(-1)',
    },
);

export const ContentStyled = styled('div')(
  {},
  (props) =>
    props.flex && {
      margin: 0,
    },
);
